import React, { useEffect } from "react";
import "./Loading.scss";


interface Props {
  color?:boolean;
}



const Loading: React.FC<Props> = ({color}) => {
 
  return (
      <div className="Loading">
        <div className={color ? "bullets color" : "bullets"}>
            <div className="bullet first"></div>
            <div className="bullet second"></div>
            <div className="bullet third"></div>
            <div className="bullet four"></div>
        </div>
    </div>       
  );
}

export default Loading;
