import React from "react";
import "./ArrowButton.scss";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "./img/arrow.svg";

interface Props {
  path?: string;
  color?: "white" | "blue" | "orange";
  onClick?: () => void;
}

const handleColorValue = (colorName: string | undefined) => {
  switch (colorName) {
    case "white":
      return "#fff";
      break;
    case "blue":
      return "#001854";
      break;
    case "orange":
      return "#ff8d03";
      break;
    default:
      return "#001854";
      break;
  }
};

const ArrowButton: React.FC<Props> = ({ path, color, onClick }) => {
  return !path ? (
    <button className="ArrowButton button" onClick={onClick}>
      <Arrow stroke={handleColorValue(color)} />
    </button>
  ) : (
    <Link className="ArrowButton" to={path || ''}>
      <Arrow stroke={handleColorValue(color)} />
    </Link>
  );
};

export default ArrowButton;
