import React, { ReactNode } from 'react';
import './Content.scss';

interface Props {
  children: ReactNode,
  width?: 'default' | 'max'
}

function Content({children, width}: Props) {
  return (
    <div className={!width ? "Content" : `Content-${width}`}>
      { children }
    </div>
  );
}

export default Content;
